import { ProductViewModel } from '~/lib/data-contract';
import { getImageTags } from './getImageTags';
import { GTMProduct } from '../model';

export const productViewModelMapper = (products: ProductViewModel[]) => {
    const gtmProducts: GTMProduct[] = [];

    products.forEach((item) => {
        const { productId, priceDetails, masterCategory, engrosColor, brand, imageTags } = item;
        const trackingName = `${brand} ${masterCategory} ${engrosColor}`;

        gtmProducts.push({
            name: trackingName,
            category: masterCategory,
            brand,
            variant: engrosColor,
            price: priceDetails?.googleDisplayPrice,
            id: productId,
            dimension11: undefined,
            ...getImageTags(imageTags),
        });
    });

    return gtmProducts;
};
