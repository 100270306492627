import { useFranchise } from '~/shared/utils';
import { runtimeConfig } from '~/shared/utils/public-variables';
import { BASKET_QUERY_KEY } from '~/features/basket/constants/keys';
import { useBasketReference } from './useBasketReference';

const { COMMERCE_URL } = runtimeConfig();

export const useBasketPaths = () => {
    const { franchiseAPIPath, queryKey } = useFranchise();
    const { basketId } = useBasketReference();

    const basketQueryKey = [BASKET_QUERY_KEY, { queryKey, basketId }];
    const baseUrl = `${COMMERCE_URL}/${franchiseAPIPath}`;
    const basketIdPath = basketId ? '/' + basketId : '';

    // Basket
    const basketPath = `${baseUrl}${basketIdPath}`;
    const basketUrl = `${baseUrl}/basket${basketIdPath}`;
    const abandonedBasketUrl = `${baseUrl}/abandonedBasket/basket${basketIdPath}`;
    const basketLineUrl = `${baseUrl}/basketline${basketIdPath}`;
    const basketToggleLoyaltySpendUrl = `${basketUrl}/bonus`;

    // Buyer information
    const addBuyerInformationUrl = `${baseUrl}/basket/addBuyerInformation${basketIdPath}/isCheckout=false`;
    const addBuyerInformationSubmitUrl = `${baseUrl}/basket/addBuyerInformation${basketIdPath}/isCheckout=true`;

    // Shipping
    const getShippingOptionsUrl = `${baseUrl}/shipment/getDeliveryOptions`;
    const addShippingUrl = `${basketUrl}/shipping/add`;
    const addShippingMethodUrl = `${basketUrl}/shipping/change/method`;

    // Payment
    const getPaymentOptionsUrl = `${baseUrl}/payment/options${basketIdPath}`;
    const addPaymentUrl = `${basketUrl}/payment/add`;
    const addOrderUrl = `${baseUrl}/order/add/${basketIdPath}`;

    // Giftcard
    const addGiftcardUrl = `${basketUrl}/giftcard/add`;
    const removeGiftcardUrl = `${basketUrl}/giftcard/remove`;

    // Abandoned basket
    const verifyCustomerUrl = `${abandonedBasketUrl}/customer`;

    return {
        basketPath,
        basketUrl,
        basketLineUrl,
        basketToggleLoyaltySpendUrl,
        addBuyerInformationUrl,
        addBuyerInformationSubmitUrl,
        getShippingOptionsUrl,
        addShippingUrl,
        addShippingMethodUrl,
        basketQueryKey,
        getPaymentOptionsUrl,
        addPaymentUrl,
        addOrderUrl,
        addGiftcardUrl,
        removeGiftcardUrl,
        verifyCustomerUrl,
    };
};
