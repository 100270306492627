import React, { memo, useState } from 'react';
import { FRANCHISE } from '~/constants/franchise';
import { isDevelopment, useFranchise } from '~/shared/utils';
import { LayoutOverlay } from '~/theme/components/LayoutOverlay/LayoutOverlay';
import { LayoutOverlayButton } from '~/theme/components/LayoutOverlayButton/LayoutOverlayButton';
import dynamic from 'next/dynamic';
import { CookieConsent, GlobalClickHandler } from '~/shared/components';
import { Notifications } from '~/shared/components/Notifications';
import { SchemaMarkup, usePage } from '~/templates/pages';
import { StyledChatButtonWrapper } from '~/shared/components/Chat/styled';

type Props = {
    children?: React.ReactNode;
};

const ChatButton = dynamic(() => import('~/shared/components/Chat/ChatButton'), { ssr: false });

const Layout = {
    [FRANCHISE.LINDBERGH]: dynamic(
        () => import('./components/FramedChalkLayout/FramedChalkLayout')
    ),
    [FRANCHISE.JUNK_DE_LUXE]: dynamic(
        () => import('./components/FramedIvoryLayout/FramedIvoryLayout')
    ),
    [FRANCHISE.TOEJEKSPERTEN]: dynamic(() => import('./components/SolidAshLayout/SolidAshLayout')),
    [FRANCHISE.WAGNER]: dynamic(() => import('./components/SolidMarbleLayout/SolidMarbleLayout')),
    [FRANCHISE.WAGNER_SE]: dynamic(
        () => import('./components/SolidMarbleLayout/SolidMarbleLayout')
    ),
    [FRANCHISE.BISON]: dynamic(() => import('./components/FramedChalkLayout/FramedChalkLayout')),
};

/**
 * Renders layout based off a key. This component also handles the pageView (Generic Datalayer) event.
 */
export const DynamicLayout = memo(
    ({ children }: Props): JSX.Element => {
        const [layoutOverlayVisible, setLayoutOverlayVisible] = useState(false);
        const { key } = useFranchise();
        const LayoutComponent = Layout[key];
        const { type } = usePage();
        const isPLP = type === 'p40productListPage';
        const ChatButtonWrapper = isPLP ? StyledChatButtonWrapper : React.Fragment;

        return (
            <>
                {isDevelopment && (
                    <>
                        <LayoutOverlay visibility={layoutOverlayVisible ? 'visible' : 'hidden'} />
                        <LayoutOverlayButton
                            onToggle={() => setLayoutOverlayVisible(!layoutOverlayVisible)}
                        />
                    </>
                )}

                <LayoutComponent children={children} />

                <GlobalClickHandler />
                <CookieConsent />
                <ChatButtonWrapper>
                    <ChatButton />
                </ChatButtonWrapper>
                <Notifications />
                <SchemaMarkup />
            </>
        );
    }
);
